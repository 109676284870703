<template>
	<div class="my">
        <div class="my-header">
            <van-image
                width="7.5rem"
                height="1.94rem"
                src="https://doc.yctop.com/weapp/zjhy/static/2021-1-19/my-bg.png"
                class="my-bg"
            />
            <div class="header-content">
                <van-image
                    round
                    width="0.9rem"
                    height="0.9rem"
                    :src="!!wx_usr.headImgUrl ? wx_usr.headImgUrl : 'https://doc.yctop.com/weapp/zjhy/static/2021-1-19/default.png'"
                />
                <p class="my-name">{{wx_usr.nickname}}</p>
            </div>
        </div>
        <div class="my-content">
            <div class="con-title">我的</div>
            <div class="con-detail">
                <div class="cell" @click="historyPreOrder">
                    <div class="cell-side">
                        <span class="iconfont iconlishi1"></span>
                        <span>我的询价</span>
                    </div>
                    <div class="cell-side cell-right">
                        <span>查看我的历史询价</span>
                        <van-icon name="arrow" />
                    </div>
                </div>
                <div class="cell" @click="favorListPage">
                    <div class="cell-side">
                        <span class="iconfont icontianchongxing-"></span>
                        <span>我的关注</span>
                    </div>
                    <div class="cell-side cell-right">
                        <span>查看我的关注</span>
                        <van-icon name="arrow" />
                    </div>
                </div>
                <div class="cell" @click="agreePage">
                    <div class="cell-side">
                        <span class="iconfont iconxieyi"></span>
                        <span>用户协议</span>
                    </div>
                    <div class="cell-side cell-right">
                        <van-icon name="arrow" />
                    </div>
                </div>
                <a href="tel:18510185570">
                    <div class="cell" >
                        <div class="cell-side">
                            <span class="iconfont icon54"></span>
                            <span style="color:#4A4A4A;">客服电话</span>
                        </div>
                        <div class="cell-side cell-right">
                            <van-icon name="arrow" />
                        </div>
                    </div>
                </a>
                
            </div>

            <div class="recommend hidden">
                <div class="recommend-title">
                    <div class="grey-line"></div>
                    <div class="middle-txt">
                        <van-image
                            width="0.28rem"
                            height="0.23rem"
                            src="https://doc.yctop.com/weapp/zjhy/static/2021-1-22/icon-recommend.png"
                            style="margin-right: 0.09rem;"
                        />
                        <span>推荐产品</span> 
                    </div>
                    <div class="grey-line"></div>
                </div>
                <div class="recommend-cont" >
                    <div class="recommend-item" v-for="(item, idx) in recommendList" :key="idx">
                        <div  @click="gotoProductDetailPage(item)">
                            <div class="item-title">{{item.title}}</div>
                            <div class="from-to">
                                <span class="from">{{item.dep}}</span>
                                <span class="iconfont iconfeiji800"></span>
                                <span class="to">{{item.des}}</span>
                            </div>
                            <div class="item-price">
                                <div class="price">
                                    <template v-if="item.price_type == 0">
                                        <em class="ask">{{item.price_remark}}</em>
                                    </template>
                                    <template v-else>
                                        <i>￥</i><em>{{item.min_price}}</em>起
                                    </template>
                                    
                                </div>
                                <div class="density" v-if="!!item.density">
                                    {{item.density}}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                <div class="change-txt" @click="loadRecommend('change')">
                    <van-icon name="replay" />换一批
                </div>
            </div>
        </div>
	</div>
</template>
<script babel>
    /* eslint-disable */
    import { mapState, mapGetters } from 'vuex';
	import { Dialog } from 'vant';
	import  REQ_HANDLE from '@/utils/request.js'
	import UTILS from '@/utils/common-utils';
	export default {
		data: ()=> ({
            recommendList:[],
            num:1,
        }),
        computed: {
			...mapState({
				wx_usr: state => state.wx_usr,
			}),
			...mapGetters({
				login: 'checkLogin',
				isCustom: 'isCustom',
			})
		},
		mounted: async function() {
			await this.loadRecommend()
        },
        methods: {
            historyPreOrder: function() {
                // 登录状态
				if(this.isCustom) {
                    this.$router.push('/historyList')
				} else {
					Dialog.confirm({
						title: '提示',
						message: '为了方便卖家能与您及时沟通，请验证手机号!',
					})
					.then(() => {
						this.$router.push('/bind-mobile')
					})
					.catch(() => {
						// on cancel
					});
				}
            },
            favorListPage: function() {
                // 登录状态
				if(this.isCustom) {
                    this.$router.push('/favor-list')
				} else {
					Dialog.confirm({
						title: '提示',
						message: '为了方便卖家能与您及时沟通，请验证手机号!',
					})
					.then(() => {
						this.$router.push('/bind-mobile')
					})
					.catch(() => {
						// on cancel
					});
				}
            },
            loadRecommend: async function(type){
               let num=type && type=='change'? this.num+1 : 1
                let url = '/api-mall/products/suggest/search/'+ num,
                    that = this,
                    res = await REQ_HANDLE.agent.$get(url)
				if(!!res) {
                    that.recommendList=res.data.data ? res.data.data : []
                    that.num=parseInt(res.data.num)
                    if(that.recommendList.length && that.recommendList.length>0){
                        for(var i=0;i<that.recommendList.length;i++){
							that.recommendList[i].price_type==1 && (that.recommendList[i].min_price=that._getMinPriceFromProduct(that.recommendList[i]) )
						}
                    }
                }
            },
            _getMinPriceFromProduct(p) {
				let min = 9999.00
				if(p.price_type == 1) {
					let {q1 = 0, q2 = 0, q3 = 0, q4 = 0, q5 = 0} = p
					q1 = parseFloat(q1)
					q2 = parseFloat(q2)
					q3 = parseFloat(q3)
					q4 = parseFloat(q4)
					q5 = parseFloat(q5)
					if (!!q5 && q5 < min) {
						min = q5
					}
					if (!!q4 && q4 < min) {
						min = q4
					}
					if (!!q3 && q3 < min) {
						min = q3
					}
					if (!!q2 && q2 < min) {
						min = q2
					}
					if (!!q1 && q1 < min) {
						min = q1
					}
					return min
				}
            },
            gotoProductDetailPage: function(product) {
                let pId = !!product ? product.id : null,
                    shopId=!!product ? product.agent_id : null
				pId && shopId && this.$router.push('/product/' + shopId + '/' + pId)
            },
            agreePage: function(){
                this.$router.push('/agreement')
            },
        }
	}
</script>
<style scoped>
@import "~@/assets/css/my.css";
</style>